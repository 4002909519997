<template>
  <b-card>
    <b-card-title class="mb-1">
      VENTAS POR CATEGORIAS
    </b-card-title>
    <b-table
      responsive="sm"
      :fields="tableColumns"
      :items="categories"
      show-empty
      empty-text="No se encontraron registros coincidentes"
    />
    <chartjs-polar-area-chart
      v-if="chartData.length > 0"
      :chart-labels="chartLabels"
      :chart-data="chartData"
      :currency-symbol="currencySymbol"
    />
  </b-card>
</template>

<script>
import {
  BTable, BCard, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import ChartjsPolarAreaChart from './charts/ChartjsPolarAreaChart.vue'

export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    ChartjsPolarAreaChart,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    currencySymbol: {
      type: String,
      default: 'S/.',
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'category', label: 'Categoria', sortable: false },
        {
          key: 'total_format',
          label: 'Total',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      items: [],
    }
  },
}
</script>

<style scoped>

</style>
