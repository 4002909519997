<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card>
        <div class="d-flex align-items-center">
          <b-form-select
            v-model="selectedYear"
            :options="years"
          />
          <b-button
            variant="primary"
            class="mx-1"
            @click="changeTypeCurrency"
          >
            <span class="text-nowrap">Cambiar reporte a {{ titleButton }}</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <chartjs-bar-chart
        v-if="showComponent"
        :selected-year="selectedYear"
        :currency-symbol="currency === 'pen' ? 'S/.' : '$'"
        :months="monthsData"
      />
      <LoadingEmptyContent v-else />
    </b-col>
    <b-col
      cols="12"
      md="5"
    >
      <ReportSaleByMonth :items-month="reportMonths" />
    </b-col>
    <b-col
      cols="12"
      md="7"
    >
      <ReportSaleByCategory
        v-if="showChartCategory"
        :categories="reportCategory"
        :chart-labels="categoryChartLabels"
        :chart-data="categoryChartData"
        :currency-symbol="currency === 'pen' ? 'S/.' : '$'"
      />
      <LoadingEmptyContent v-else />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BCard, BFormSelect, BButton,
} from 'bootstrap-vue'
import ChartjsBarChart from '@/views/dramox/sale/components/charts/ChartjsBarChart'
import ReportSaleByMonth from '@/views/dramox/sale/components/ReportSaleByMonth'
import ReportSaleByCategory from '@/views/dramox/sale/components/ReportSaleByCategory'
import LoadingEmptyContent from '@core/components/loading/LoadingEmptyContent'

export default {
  components: {
    LoadingEmptyContent,
    ReportSaleByCategory,
    ReportSaleByMonth,
    BRow,
    BCol,

    ChartjsBarChart,
    BCard,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      years: [],
      currency: 'pen',
      reportMonths: {
        months: [],
      },
      reportCategory: [],
      showComponent: true,
      showChartCategory: true,
    }
  },
  computed: {
    titleButton() {
      if (this.currency === 'pen') return 'dolares'
      return 'soles'
    },
    monthsData() {
      return this.reportMonths.months.map(item => item.total)
    },
    categoryChartLabels() {
      return this.reportCategory.map(item => item.category)
    },
    categoryChartData() {
      return this.reportCategory.map(item => item.total)
    },
  },
  watch: {
    selectedYear() {
      this.fetchReportMonth()
      this.fetchReportCategory()
    },
    currency() {
      this.fetchReportMonth()
      this.fetchReportCategory()
    },
  },
  created() {
    this.loadYears()
    this.fetchReportMonth()
    this.fetchReportCategory()
  },
  methods: {
    async fetchReportMonth() {
      try {
        this.showComponent = false
        const response = await this.$http.get('admin/report/sale-month', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
          },
        })
        this.reportMonths = response.data.payload.results
        this.showComponent = true
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportCategory() {
      try {
        this.showChartCategory = false
        const response = await this.$http.get('admin/report/sale-category', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
          },
        })
        this.reportCategory = response.data.payload.results
        this.showChartCategory = true
      } catch (e) {
        console.log(e)
      }
    },
    loadYears() {
      const date = new Date()
      let base = date.getFullYear()
      for (base; base >= 2016; base--) {
        this.years.push(base)
      }
    },
    changeTypeCurrency() {
      if (this.currency === 'pen') {
        this.currency = 'usd'
      } else {
        this.currency = 'pen'
      }
    },
  },
}
</script>

<style scoped>

</style>
